<template>
    <div id="buttonGSI"></div>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapState } from "vuex";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    components: {},
    computed: {
        ...mapState("authsMgmt", ["loginOps"]),
    },
    methods: {
        renderGSIButton() {
            window.google.accounts.id.initialize({
                client_id:
                    "281981058345-ek0ls2ibbjka6kgf1vkmps2n2o990p3f.apps.googleusercontent.com",
                callback: this.googleOAuthLogin,
            });
            window.google.accounts.id.renderButton(
                document.getElementById("buttonGSI"),
                {
                    type: "standard",
                    theme: "outline",
                    size: "large",
                    shape: "rectangular",
                }
            );
            // window.google.accounts.id.prompt();
        },
        googleOAuthLogin(data) {
            this.$store.dispatch("authsMgmt/googleISLogin", data);
        },
    },
    name: "GoogleIdentityService",
    props: {},

    // Lifecycle hooks.
    beforeCreate() {
        // let scriptGIS = document.createElement("script");
        // scriptGIS.src = "https://accounts.google.com/gsi/client";
        // scriptGIS.defer = true;
        // scriptGIS.async = true;
        // document.head.appendChild(scriptGIS);
    },
    created() {},
    beforeMount() {},
    mounted() {
        this.renderGSIButton();
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

